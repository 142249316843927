import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { FlexService } from 'src/app/core/services/flex.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ListResponse } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { FilterStrategy, ListFilterGroupResponse, ListView, ListViewResponse } from 'src/lib/views';
import { GetQuery } from 'src/lib/generics';
import { Store } from 'src/app/core/services/store.service';

export const flexResolver: ResolveFn<{ flexView: ListView } | null> = async (route) => {
  const api = inject(ThalosApiService);
  const router = inject(Router);
  const flexService = inject(FlexService);
  const store: Store = inject(Store);

  const id = await resolveId(route, router);

  const query: GetQuery<ListViewResponse> = { filters: { id } };
  const flexViewData = await fetchFlexView(api, query);
  if (!flexViewData) await redirectTo404(router);

  const userAuthorizedGroupCNS = store.snapshot((state) => state.user.userGroups).map((group) => group.cn);

  const filterGroups = await fetchFilterGroups(api, flexViewData.id);
  flexViewData.filterGroups = filterGroups.sort((a, b) => a.defaultOrder - b.defaultOrder);
  const filterGroupsAuthorizedGroupsCNS = [...new Set(filterGroups.flatMap((item) => item.authorizedGroups.map((group) => group.cn)))];

  const allFilterGroups = await fetchAllFilterGroups(api, flexViewData.id);

  const flexView = flexService.loadFlexView(flexViewData);
  if (!flexView) await redirectTo404(router);

  // If flexview has filters and filterStrategy is different to NO_FILTER
  if (allFilterGroups.length > 0 && flexViewData.filterStrategy !== FilterStrategy.NO_FILTER) {
    // Determines whether to display an error message based on the following conditions:
    const isUnauthorizedFilter =
      filterGroupsAuthorizedGroupsCNS.length === 0 || // No user filter's authorized groups
      userAuthorizedGroupCNS.length === 0 || // No user's authorized groups
      !userAuthorizedGroupCNS.some((value) => filterGroupsAuthorizedGroupsCNS.includes(value)); // No overlap between filter's user groups and user's groups
    if (isUnauthorizedFilter) return { flexView: { ...flexView, isUnauthorizedFilter, message: 'This Flex View require filters and none are assigned.' } };
  }

  return { flexView };
};

async function resolveId(route: any, router: Router): Promise<number | null> {
  let id = route.params['id'];
  if (!id) {
    const flexView: ListViewResponse = route.data['flexView'];
    if (!flexView) await redirectTo404(router);
    id = flexView.id;
  }
  return id;
}

async function fetchFlexView(api: ThalosApiService, query: GetQuery<ListViewResponse>): Promise<ListViewResponse | null> {
  const response = await api.run<ListViewResponse>(endpoints.getFlexView, query, null, { hideErrorPopup: true });
  return response;
}

async function fetchFilterGroups(api: ThalosApiService, flexViewId: number): Promise<ListFilterGroupResponse[]> {
  const response = await api.run<ListResponse<ListFilterGroupResponse>>(endpoints.listFlexFilterGroups, { flexViewId }, { list: [], count: 0 });
  return response.list;
}

async function fetchAllFilterGroups(api: ThalosApiService, flexViewId: number): Promise<ListFilterGroupResponse[]> {
  const response = await api.run<ListResponse<ListFilterGroupResponse>>(endpoints.listAllFlexFilterGroupsAdmin, { filters: { flexViewId } }, { list: [], count: 0 });
  return response.list;
}

async function redirectTo404(router: Router): Promise<null> {
  await router.navigate(['error/404']);
  return null;
}
